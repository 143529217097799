import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

const Submitted = (props) => {

    const [redirect, set_redirect] = useState({})

    function on_clicked_exit(e) {
        set_redirect({ path: "/dashboard/orders"})
        //return <Redirect to={state.context.redirect}/>
    }

    if(redirect.path) {
        return <Redirect to={redirect.path}/>
    }

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-2xl w-full flex flex-wrap flex-col items-center mt-14 space-y-8">
                <h2 className="text-center text-3xl font-extrabold text-gray-900">
                    Your order has been submitted. <br/>
                    ID: {props.location.state.order_id}
                </h2>
                <div className="max-w-xl">
                    <p className="text-center text-xl text-gray-700">
                        You will receive an order confirmation email shortly.<br/>
                        If you have not received any correspondence from us, please check your spam or junk mail folders.<br/>
                        Else, please reach out to us at <a className="underline" href="mailto:jobtravelers@abcorp.com">jobtravelers@abcorp.com</a>.
                    </p>
                </div>
                <div className={"flex justify-center w-full"}>
                    <button className={"uploadButton"} onClick={on_clicked_exit}>
                        Okay
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Submitted;