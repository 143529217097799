import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import {AppContext} from "AppContext";


const OrderSummary = (props) => {

    const { config } = useContext(AppContext);
    const [ open, set_open ] = useState(true);

    const total_cost = props.value.metadata.parts.reduce((acc, curr) => acc + curr.price_total, 0);

    const finishes = [
        { text: "MJF Finish",               value: "MJF_FINISH"},
        { text: "Vapor Smooth",             value: "VAPOR_SMOOTHING"},
        { text: "Black Dye",                value: "BLACK_DYE"},
        { text: "Vapor Smooth / Black Dye", value: "AMT_BLACK_DYE"},
        { text: "Sinter",                   value: "SINTER"}
    ];

    const units = [
        { text: "mm", value: "UNITS_MM"},
        { text: "in", value: "UNITS_IN"}
    ];


    function imgNotFound(image) {
        image.target.onerror = null;
        image.target.src = "/no_image.png";
        image.target.alt = "No image found placeholder"
        return true;
    }

    function toggleOpen() {
        set_open(previousOpen => !previousOpen);
    }
    

    return (
        <div className={"rounded-md border width-full mt-4"}>
            <div className={"width-full flex flex-row bg-gray-200 rounded-t pt-4 pl-4 pb-4 pr-4 space-x-8"} onClick={toggleOpen}>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Customer:</div>
                    <div className={"font-medium"}>{props.value.Account.company}</div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Total Cost:</div>
                    <div>{total_cost.toFixed(2)}</div>
                </div>
                <div className={"flex flex-grow flex-col items-end"}>
                    <div className={"flex text-sm"}>Order Id: {props.value.id}</div>
                    <div className={"flex mt-1"}>
                    {
                        open ?
                        <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={1} d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={1} d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                        </svg>
                        :
                        <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth={1}  d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                        </svg>
                    }
                    </div>
                </div>
            </div>
            { open &&
                <div className={"width-full bg-white p-4"}>
                    { props.value.metadata.parts.map((item, index) => {
                        return (
                            <div className={index === props.value.metadata.parts.length-1 ? "width-full" : "width-full mb-4 border-b pb-2 border-dashed border-gray-500"} key={index}>
                                <div className={"flex flex-cols space-x-4"}>
                                    <div style={{ minWidth: "96px", width: "96px", height: "96px", minHeight: "96px"}}>
                                    <div ><img src={config.storage.host + "/orders/" + props.value.acctId + "/" + item.snapshot_filename}
                                    onError={ (img) => imgNotFound(img) } /></div>
                                    </div>
                                    <div className={"grid grid-cols-5 gap-4 flex-grow"}>
                                        <div>
                                        <div className={"text-sm font-semibold"}>
                                                Model filename:
                                            </div>
                                            <div className={"break-words"}>
                                                {item.original_filename.replaceAll("_", "_\u200B").replaceAll("-", "-\u200B")}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-sm font-semibold"}>
                                                Material:
                                            </div>
                                            <div>
                                                {item.material}
                                            </div>
                                        </div>
                                        <div className={"col-span-3"}>
                                            <div className={"text-sm font-semibold"}>
                                                Finish:
                                            </div>
                                            <div>
                                                { finishes.find((finishType) => finishType.value === item.finish).text }
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-sm font-semibold"}>
                                                Volume:
                                            </div>
                                            <div>
                                                {item.volume.toFixed(2)} { units.find((unit) => unit.value === item.units).text }<sup>3</sup>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-sm font-semibold"}>
                                                Price:
                                            </div>
                                            <div>
                                                {item.price_per_component.toFixed(2)}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-sm font-semibold"}>
                                                Quantity:
                                            </div>
                                            <div>
                                                {item.quantity}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"text-sm font-semibold"}>
                                                Total Cost:
                                            </div>
                                            <div>
                                                {item.price_total.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {item.comments !== "" &&
                                <div className={"pr-2 pl-2 pt-2"}>
                                    <label className="block">
                                        <div className="text-sm font-semibold">
                                            Special Instructions / Comments:
                                        </div>
                                        <div>
                                            {item.comments.trim().replaceAll("\n", " || ")}
                                        </div>
                                    </label>
                                </div>
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

const Content = (props) => {

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
    };

    const { config } = useContext(AppContext);
    const [orders, set_orders] = useState([]);
    const [materials, set_materials] = useState([]);
    const [loading, set_loading] = useState(true);
    const [ dateview, set_dateview] = useState(new Date().toLocaleDateString("en-CA")); 
    const [ displayDate, set_displayDate ] = useState(new Date().toLocaleDateString("en-US", options));

    useEffect(() => {
        // Retrieve material data from the server:
        const action = async () => {
            try {
                const rxMaterials = await axios.get(config.api.base + '/materials');
                set_materials(rxMaterials.data);
            } catch(x) {
                console.log(x);
            }

        }
        action(config.api.base);

    }, [])

    useEffect(() => { 
        // Retrieve all quotes from the server:
        const action = async () => {
            try {
                // Retrieve material data from the server:
                const response = await axios.get(config.api.base + '/orders/ondate/' + dateview); 
                set_loading(false);
                set_orders(response.data);
            } catch(x) {
                console.log(x);
                set_loading(false);
                set_orders([]);
            }

        }
        action();

    }, [config.api.base, displayDate])

    function onDateChange(args) {
        set_dateview(args.target.value);
    }
    
    function onDatePick(_) {
        set_loading(true);
        set_displayDate(new Date(dateview).toLocaleDateString("en-US", options));
    }
    

    if (loading) {
        return (
            <div className={"container mx-auto"}>
                <div className={"flex w-full border-b-2 border-solid py-4"}>
                    <div>
                        <span className={"text-3xl"}>
                            Order History
                        </span>
                    </div>
                </div>
                <div className={"flex flex-col flex-grow w-full h-full justify-center items-center"}>
                    <div className={"mt-48"}>
                        <span className={"text-2xl text-gray-700"}>
                            Loading...
                        </span>
                    </div>
                    <div style={{ width: "240px", height: "240px"}}>
                        <img src={"/loading-transparent.gif"} style={{ height: "100%"}} alt="A loading gif - Many 2D planes moving and intersecting in 3D" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"container mx-auto"}>
                <div className={"flex w-full border-b-2 border-solid py-4"}>
                    <div className={"flex items-end"}>
                        <span className={"text-3xl"}>
                            Order History for {displayDate}
                        </span>
                    </div>
                    <div className={"flex flex-grow justify-end items-center"}>
                        <label className={"pl-2"}>
                            <span className={"text-2xl"}>Change Date to:</span>
                            <input 
                             type="date" 
                             value={dateview} 
                             onChange={onDateChange} 
                             onFocus={(e) => {e.target.showPicker()}} 
                             onKeyDown={(e) => {e.preventDefault();}} 
                             className={"ml-2 pl-1 text-lg border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"}
                            />
                        </label>
                        <button className={"ml-4 px-5 estimateButton"} onClick={onDatePick}>
                            Go
                        </button>
                    </div>
                </div>
                <div className={"flex flex-grow w-full pt-4 text-2xl"}>
                    {
                        orders.length ? 
                            orders.length + " order(s) found, " + 
                            orders.reduce(
                                (acc, curr) => acc + curr.metadata.parts.reduce((acc, curr) => acc + curr.price_total, 0), 0
                            ).toLocaleString("en-US", { style: "currency", currency: "USD", currencyDisplay: "narrowSymbol" }) + " total sales:" 
                        : 
                            "No orders for " + displayDate + "." 
                    }
                </div>
                <div>
                    { 
                        orders.map((item, index) => {
                            return <OrderSummary value={item} materials={materials} key={index} index={index}/>
                        })
                    }
                </div>
            </div>
        )
    }
}


const Orders = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Orders;
