import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import { AppContext } from "AppContext";


const OrderSummary = (props) => {

    const { identity, config } = useContext(AppContext);

    const total_cost = props.value.metadata.parts.reduce((acc, curr) => acc + curr.price_total, 0);

    const finishes = [
        { text: "MJF Finish",               value: "MJF_FINISH"},
        { text: "Vapor Smooth",             value: "VAPOR_SMOOTHING"},
        { text: "Black Dye",                value: "BLACK_DYE"},
        { text: "Vapor Smooth / Black Dye", value: "AMT_BLACK_DYE"},
        { text: "Sinter",                   value: "SINTER"}
    ];

    const units = [
        { text: "mm", value: "UNITS_MM"},
        { text: "in", value: "UNITS_IN"}
    ];

    function imgNotFound(image) {
        image.target.onerror = null;
        image.target.src = "/no_image.png";
        image.target.alt = "No image found";
        return true;
    }

    return (
        <div className={"rounded-md border width-full mt-4"}>
            <div className={"width-full flex flex-row bg-gray-200 rounded-t pt-4 pl-4 pb-4 pr-4 space-x-8"}>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Order Placed:</div>
                    <div>{props.value.created_at.toDateString()}</div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"text-sm"}>Total Cost:</div>
                    <div>{total_cost.toFixed(2)}</div>
                </div>
                <div className={"flex flex-grow justify-end"}>
                    <div className={"text-sm"}>Order Id: {props.value.id}</div>
                </div>
            </div>
            <div className={"width-full bg-white p-4"}>
                { props.value.metadata.parts.map((item, index) => {
                    return (
                        <div className={index === props.value.metadata.parts.length-1 ? "width-full" : "width-full mb-4 border-b pb-2 border-dashed border-gray-500"} key={index}>
                            <div className={"flex flex-cols space-x-4"}>
                                <div style={{ minWidth: "96px", width: "96px", height: "96px", minHeight: "96px"}}>
                                    <div ><img src={config.storage.host + "/orders/" + identity.userAcctId + "/" + item.snapshot_filename}
			                       onError={ (img) => imgNotFound(img) } alt={"Screenshot of part " + item.original_filename} /></div>
                                </div>
                                <div className={"grid grid-cols-5 gap-4 flex-grow"}>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Model filename:
                                        </div>
                                        <div className={"break-words"}>
                                            {item.original_filename.replaceAll("_", "_\u200B").replaceAll("-", "-\u200B")}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Material:
                                        </div>
                                        <div>
                                            {item.material}
                                        </div>
                                    </div>
                                    <div className={"col-span-3"}>
                                        <div className={"text-sm font-semibold"}>
                                            Finish:
                                        </div>
                                        <div>
                                            { finishes.find((finishType) => finishType.value === item.finish).text }
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Volume:
                                        </div>
                                        <div>
                                            {item.volume.toFixed(2)} { units.find((unit) => unit.value === item.units).text }<sup>3</sup>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Price:
                                        </div>
                                        <div>
                                            {item.price_per_component.toFixed(2)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Quantity:
                                        </div>
                                        <div>
                                            {item.quantity}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"text-sm font-semibold"}>
                                            Total Cost:
                                        </div>
                                        <div>
                                            {item.price_total.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
			                {item.comments !== "" &&
                            <div className={"pr-2 pl-2 pt-2"}>
				                <label className="block">
                                    <div className="text-sm font-semibold">
				                        Special Instructions / Comments:
				                    </div>
				                    <div>
				                        {item.comments.trim().replaceAll("\n", " || ")}
				                    </div>
				                </label>
                            </div>
			                }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

/** QuoteItem was not being used, commented out - 10/21/24 ez
const QuoteItem = (props) => {

    // Retrieve configuration information from global context:
    const { identity, config } = useContext(AppContext);
    const [redirect, setRedirect] = useState(false);

    var link = "/dashboard/orders/" + props.value.id;

    let timestamp = props.value.created_at;
    let formatted = '';
    formatted += (timestamp.getMonth() +1 );
    formatted += '/' + (timestamp.getDay().length < 2 ? '0' + timestamp.getDay() : timestamp.getDay());
    formatted += '/' + timestamp.getFullYear();
    formatted += ' - ';
    formatted += (timestamp.getHours().length < 2 ? '0' + timestamp.getHours() : timestamp.getHours());
    formatted += ':';
    formatted += (timestamp.getMinutes().length < 2 ? '0' + timestamp.getMinutes() : timestamp.getMinutes());

    const bgcolor = props.index % 2 === 0 ? "bg-green-50"   : "bg-green-100";
    const hvcolor = props.index % 2 === 0 ? "bg-green-200"  : "bg-green-300";

    // Determine the material name:
    function getMaterialText(m) {
        console.log("looking up: " + m)
        console.log(props.materials)
        let materialText = "unknown";
        for (let x = 0; x != props.materials.length; x++) {
            if (props.materials[x].id === m) {
                materialText = props.materials[x].name;
            }
        }
        return materialText;
    }

    if(redirect === true) {
        return  <Redirect to={{ pathname: link }} />
    }

    return(
        <div className={"flex mt-4 space-x-4 border-b-2 " + bgcolor + " hover:" + hvcolor } onClick={(e) => setRedirect(true)}>
            <div>
                <img src={'/icon-file-stl.png'} width={'64px'} height={'64px'}></img>
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return <div key={index}><img src={config.storage.host + "/orders/" + identity.userAcctId + "/" + item.snapshot_filename} width={96} height={96}/></div>
                })}
            </div>
            <div className={'flex flex-col'}>
                { props.value.metadata.parts.map((item, index) => {
                    return (
                        <div key={index}>
                            <span>Material: { getMaterialText(item.material) }</span>
                            <span className={"ml-4"}>Quantity: { item.quantity }</span>
                            <span className={"ml-4"}>Price: ${ item.price}</span>
                            <span className={"ml-4"}>Total Cost: ${ item.quantity * item.price }</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
 */

const Content = (props) => {

    // const history = useHistory();
    const { identity, config } = useContext(AppContext);
    const [redirect, set_redirect] = useState({ redirect: false, path: ""})
    const [orders, set_orders] = useState([]);
    const [materials, set_materials] = useState([]);
    const [loading, set_loading] = useState(true);

    const [state, dispatch] = useMachine(() => {
        return createMachine({
            id: "component_state_machine",
            initial: "ready",
            states : {
                ready: {
                    on: {
                        REQUEST_CREATE_ORDER : 'redirect_create_order'
                    }
                },
                redirect_create_order: {
                    entry: on_redirect_create_order
                }
            }
        })
    })

    useEffect(()=>{
        // Retrieve all quotes from the server:
        const action = async () => {
            try {
                // Retrieve material data from the server:
                const rxMaterials = await axios.get(config.api.base + '/materials');
                set_materials(rxMaterials.data);
                const response = await axios.get(config.api.base + '/orders/');
                set_loading(false);
                set_orders(response.data);
                console.log("::::: ORDER RESPONSE :::::")
                console.log(response.data);
            } catch(x) {
                console.log(x);
            }

        }
        action();

    }, [config.api.base])

    function on_redirect_create_order(context, event) {
        set_redirect({ redirect: true, path: "/dashboard/orders/new"})
    }


    function on_click_create_order() {
        dispatch("REQUEST_CREATE_ORDER")
    }

    if(redirect.redirect === true) {
        return <Redirect push to={redirect.path}/>
    }

    if (loading) {
        return (
            <div className={"container mx-auto"}>
                <div className={"flex w-full border-b-2 border-solid py-4"}>
                    <div>
                        <span className={"text-3xl"}>
                            Orders{ identity.userAdmin && ` - ${identity.userComp}` }
                        </span>
                    </div>
                </div>
                <div className={"flex flex-col flex-grow w-full h-full justify-center items-center"}>
                    <div className={"mt-48"}>
                        <span className={"text-2xl text-gray-700"}>
                            Loading...
                        </span>
                    </div>
                    <div style={{ width: "240px", height: "240px"}}>
                        <img src={"/loading-transparent.gif"} style={{ height: "100%"}} alt="A loading gif - Many 2D planes moving and intersecting in 3D" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"container mx-auto"}>
                <div className={"flex w-full border-b-2 border-solid py-4"}>
                    <div>
                        <span className={"text-3xl"}>
                            Orders{ identity.userAdmin && ` - ${identity.userComp}` }
                        </span>
                    </div>
                    <div className={"flex-grow"}>
                        <div className={"flex h-full justify-end items-center "}>
                            <button className={"green-button h-10"}  onClick={on_click_create_order} style={{ marginRight: '5px'}}>
                                New Order
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    { orders.map((item, index) => {
                        return <OrderSummary value={item} materials={materials} key={index} index={index}/>
                    })}
                </div>
            </div>
        )
    }
}


const Orders = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Orders;
