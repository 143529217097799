import React from 'react';

const ApxBusy = (props) => {

    return(
        <div className={"fixed z-10 inset-0 overflow-y-auto " + ((props.show === true) ? "visible" : "hidden")}>
            <div className={"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"}>
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className={"inline-block align-center bg-white py-8 px-12 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg" + (props.show_progress ? " sm:w-full" : "")} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={"flex w-full justify-center items-end text-2xl"}>
                        { !props.show_progress &&
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width={1.3} d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
                            </svg>
                        }
                        <span className="pb-px pl-3">
                            { props.message }
                        </span>
                    </div>
                    { props.show_progress &&
                        <div className="h-3 relative max-w-xl rounded-full overflow-hidden mt-4">
                            <div className="w-full h-full bg-gray-200 absolute"></div>
                            <div id="bar" className="h-full bg-green-500 relative" style={{ width: props.percentage + "%"}}></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ApxBusy;
